import * as AuthTypes from 'core/logic/authentication/auth.types'
import { types as AppTypes } from 'core/app'
import { builder } from 'core/helpers'
import { splitToChunks } from 'core/helpers/arrays'
import {
  AssetType,
  BaseAssetType,
  PartialTransaction,
} from 'core/logic/asset/asset.types'
import { auth, firestore, functions, storage } from 'core/modules/firebase'
import service from 'core/modules/localstorage'
import firebase from 'firebase/compat/app'
import { LS_KEYS } from '../localstorage/service'
import {
  BidPayload,
  BidResponse,
  ConfirmWithdrawalParams,
  ConfirmWithdrawalResponse,
  CreateSignedOfferParams,
  CreateSignedOfferResponse,
  DocumentSnapshotObserver,
  ForwardApprovalParams,
  ForwardApprovalResponse,
  OpenParams,
  OpenResponse,
  PaymentParams,
  PaymentResponse,
  PurchaseParams,
  PurchaseResponse,
  QuerySnapshotObserver,
  WithdrawalParams,
  WithdrawalResponse,
} from './firebase.types'
import { DeliveryType } from 'core/logic/delivery/delivery.types'
import { SnapshotFactoryReturn } from '@onepercentio/one-ui/dist/hooks/useFirestoreWatch'
import {
  FormStep,
  SerializableAnswerByField,
} from 'features/CVM88/Form/Form.types'
import { RarumUserProfile } from 'core/logic/user/user.types'
import { TransferType } from 'core/logic/transfer/transfer.types'
import {
  and,
  collection,
  doc,
  getCountFromServer,
  query,
  where,
  getDoc,
  getDocs,
} from 'firebase/firestore'
import { Airdrop } from 'core/logic/airdrop/airdrop.types'
import { OfferType } from 'core/logic/drop/drop.types'
import { ChatReply, ChatThread } from 'features/ChatRoom/ChatRoom.types'
import { ToFirebaseType } from '@onepercentio/one-ui/dist/types'
import { Challenge, ChallengeDTO } from 'core/logic/challenges/challenges.types'
import { ClaimException } from 'context/Claim'
import {
  CountersFirebaseType,
  CountersStateType,
} from 'core/logic/counters/counters.types'

// Firestore - Tenant constants
const TENANT_PATH = `tenants`

// Firestore - Offer constants
export const OFFERS_PATH = `tenants/${process.env.REACT_APP_TENANT_IDENTITY_ID}/offers`
const OFFERS_ORDERING = {
  FIELD: 'begin',
  DIRECTION: 'asc' as firebase.firestore.OrderByDirection,
}

// Firestore - Counters constants
const COUNTERS_PATH = 'counters'
const COUNTERS_FILTER = 'offerId'

// Firestore - Purchase constants
export const PURCHASE_PATH = `tenants/${process.env.REACT_APP_TENANT_IDENTITY_ID}/purchases`
const PURCHASE_FUNCTION = 'purchases-purchase'
const CLAIM_FUNCTION = 'airdrops-signedClaim'
const CLAIM_PUBLIC_FUNCTION = 'airdrops-publicClaim'
const CREATE_SIGNED_OFFER_FUNCTION = 'offers-createSignedOffer'
const UPDATE_WALLET_FUNCTION = 'accounts-setExternalWallet'
const FORWARD_APPROVAL_FUNCTION = 'accounts-forwardApproval'
const PAYMENT_FUNCTION = 'purchases-payment'

const TRANSFER_BATCH_REQUEST = 'transfers-transferBatchRequest'
const TRANSFER_BATCH_CONFIRM = 'transfers-transferBatchConfirmation'

// Firestore - Delivery constants
const DELIVERIES_PATH = `tenants/${process.env.REACT_APP_TENANT_IDENTITY_ID}/deliveries`

const TRANSACTIONS_PATH = `transactions`

// Firestore - Asset constants
const ASSETS_PATH = `tenants/${process.env.REACT_APP_TENANT_IDENTITY_ID}/assets`

// Firestore - Galleries constants
export const GALLERIES_PATH = `tenants/${process.env.REACT_APP_TENANT_IDENTITY_ID}/galleries`

// Firestore - Terms constants
export const TERMS_PATH = `terms`

// Functions
const OPEN_FUNCTION = 'deliveries-open'
const CLAIM_DELIVERY_FUNCTION = 'deliveries-claim'
const WITHDRAWAL_FUNCTION = 'transfers-transferRequest'
const CONFIRM_WITHDRAWAL_FUNCTION = 'transfers-transferConfirmation'
const BID_FUNCTION = 'auctions-placeBidOffChain'
export const AUTHENTICATE_WITH_SIGNATURE_FUNCTION = 'accounts-walletLogin'

export const FB_LOGIN_REDIRECT = 'firebase-login'

export const getPlatformfromProviderId = (provider: string) => {
  if (provider.startsWith('google')) return AuthTypes.Platform.gmail
  else if (provider.startsWith('facebook')) return AuthTypes.Platform.facebook
  else return
}

export const requestPasswordReset = async ({
  email,
}: Partial<AuthTypes.Credentials>): Promise<AppTypes.ServiceResult> => {
  if (!email) {
    return builder.adapterError('email.required')
  }
  try {
    await auth.sendPasswordResetEmail(email)
  } catch (e: any) {
    return builder.adapterError('password.reset')
  }
  return builder.adapterOK()
}

export const verifyEmail = async ({
  code,
}: AuthTypes.CheckingCode): Promise<AppTypes.ServiceResult> => {
  try {
    // prettier-ignore
    await Promise.all([
      auth.checkActionCode(code),
      auth.applyActionCode(code),
    ])
  } catch (e: any) {
    return builder.adapterError('verifyEmail.error')
  }
  return builder.adapterOK()
}

export const loginByEmail = async ({
  email,
  password,
}: AuthTypes.Credentials): Promise<AppTypes.ServiceResult> => {
  try {
    await auth.signInWithEmailAndPassword(email, password)
  } catch (e: any) {
    return builder.adapterError(e?.code || 'login.error')
  }
  return builder.adapterOK()
}

export const logout = async (): Promise<AppTypes.ServiceResult> => {
  try {
    await auth.signOut()
  } catch (e) {
    return builder.adapterError('logout.error')
  }
  return builder.adapterOK()
}

export const remove = async (): Promise<AppTypes.ServiceResult> => {
  try {
    await auth.currentUser?.delete()
  } catch (e) {
    return builder.adapterError('remove.error')
  }
  return builder.adapterOK()
}

export const registerByEmail = async ({
  email,
  password,
}: AuthTypes.Credentials): Promise<AppTypes.ServiceResult> => {
  try {
    await auth.createUserWithEmailAndPassword(email, password)
  } catch (e: any) {
    return builder.adapterError(e?.code || 'register.error')
  }
  return builder.adapterOK()
}

const _buildGmailProvider = () => {
  const provider = new firebase.auth.GoogleAuthProvider()
  provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
  provider.addScope('https://www.googleapis.com/auth/userinfo.email')
  return provider
}

const _buildFacebookProvider = () => {
  const provider = new firebase.auth.FacebookAuthProvider()
  provider.addScope('email')
  provider.addScope('public_profile')
  return provider
}

const PLATFORM_PROVIDER = {
  [AuthTypes.Platform.gmail]: _buildGmailProvider,
  [AuthTypes.Platform.facebook]: _buildFacebookProvider,
}

export const loginByProvider = async (
  platform: AuthTypes.Platform.facebook | AuthTypes.Platform.gmail
): Promise<AppTypes.ServiceResult> => {
  try {
    const provider = PLATFORM_PROVIDER[platform]()
    auth.signInWithPopup(provider)
  } catch (e: any) {
    return builder.adapterError(e?.code || `${platform}Login.error`)
  }
  return builder.adapterOK()
}

export const isRedirectingLogin = () => {
  const redirect = service(LS_KEYS.REDIRECT).getString()
  if (redirect.ok && redirect.data) {
    return redirect.data === FB_LOGIN_REDIRECT
  }
  return false
}

/* =========
   FETCHERS
   ========= */

export const fetchDrops = ({ onSnapshot }: QuerySnapshotObserver) => {
  return firestore
    .collection(OFFERS_PATH)
    .where('status', '!=', 'INACTIVE')
    .orderBy('status')
    .orderBy(OFFERS_ORDERING.FIELD, OFFERS_ORDERING.DIRECTION)
    .onSnapshot(onSnapshot)
}

export const fetchDrop = ({
  dropId,
  onSnapshot,
}: { dropId: string } & DocumentSnapshotObserver) => {
  return firestore.collection(OFFERS_PATH).doc(dropId).onSnapshot(onSnapshot)
}

export const fetchDropBids = ({
  dropId,
  onSnapshot,
}: { dropId: string } & QuerySnapshotObserver) => {
  return firestore
    .collection(`${OFFERS_PATH}/${dropId}/bids`)
    .orderBy('created', 'desc')
    .onSnapshot(onSnapshot)
}

export const fetchGalleries = ({ onSnapshot }: QuerySnapshotObserver) => {
  return firestore.collection(GALLERIES_PATH).onSnapshot(onSnapshot)
}

export const fetchGallery = ({
  galleryId,
  onSnapshot,
}: { galleryId: string } & DocumentSnapshotObserver) => {
  return firestore
    .collection(GALLERIES_PATH)
    .doc(galleryId)
    .onSnapshot(onSnapshot)
}

// TODO: Break this query into chunks to avoid the 10 item limit in "IN" queries
export const fetchCounters = ({
  dropIds: allDropIds,
  onSnapshot,
}: {
  dropIds: string[]
  onSnapshot(counterMap: Record<OfferType['id'], number | undefined>): void
}) => {
  const chunks = splitToChunks(allDropIds, 10)
  const oldDocsByChunk: { [k: number]: CountersFirebaseType[] } = {}
  const groupedCounters: CountersStateType = {}
  const observers = chunks.map((dropIds, i) =>
    firestore
      .collectionGroup(COUNTERS_PATH)
      .where(COUNTERS_FILTER, 'in', dropIds)
      .onSnapshot((docs) => {
        const oldDocs = oldDocsByChunk[i]
        oldDocsByChunk[i] = docs.docs.map(
          (a) => a.data() as CountersFirebaseType
        )

        if (docs.docs.length === 0) {
          for (let offerId of dropIds) groupedCounters[offerId] = 0
        } else {
          docs.docChanges().forEach((a) => {
            const changedDoc = a.doc.data() as CountersFirebaseType
            const oldDoc =
              a.type === 'modified' ? oldDocs[a.newIndex] : undefined
            const { count, offerId } = changedDoc
            if (oldDoc) groupedCounters[offerId]! -= oldDoc.count
            if (groupedCounters[offerId]) {
              groupedCounters[offerId]! += count
            } else {
              groupedCounters[offerId] = count
            }
          })
        }

        onSnapshot({ ...groupedCounters })
      })
  )
  return () => {
    observers.forEach((a) => a())
  }
}

export const fetchPurchases = ({ onSnapshot }: QuerySnapshotObserver) => {
  const userId = auth.currentUser?.uid
  if (!userId) {
    throw new Error('User is not logged')
  }
  return firestore
    .collection(PURCHASE_PATH)
    .where('user.id', '==', userId)
    .where('paymentStatus', '==', 'ACTIVE')
    .onSnapshot(onSnapshot)
}

export const fetchPurchase = ({
  purchaseId,
  onSnapshot,
}: { purchaseId: string } & DocumentSnapshotObserver) => {
  return firestore
    .collection(PURCHASE_PATH)
    .doc(purchaseId)
    .onSnapshot(onSnapshot)
}

export const fetchDeliveries = ({ onSnapshot }: QuerySnapshotObserver) => {
  const userId = auth.currentUser?.uid
  if (!userId) {
    throw new Error('User is not logged')
  }
  return firestore
    .collection(DELIVERIES_PATH)
    .where('user.id', '==', userId)
    .where('status', 'in', ['CLOSED', 'UNCLAIMED'])
    .onSnapshot(onSnapshot)
}

export const fetchDelivery = ({
  deliveryId,
  onSnapshot,
}: { deliveryId: string } & DocumentSnapshotObserver) => {
  return firestore
    .collection(DELIVERIES_PATH)
    .doc(deliveryId)
    .onSnapshot(onSnapshot)
}

export const fetchAssetsByTokens = async ({
  tokenIds,
}: {
  tokenIds: (string | number)[]
}) => {
  const chunks = splitToChunks(tokenIds, 10)
  const promises = chunks.map((chunk) =>
    firestore.collection(ASSETS_PATH).where('tokenId', 'in', chunk).get()
  )
  const result = await Promise.all(promises)
  const singleArray = result.reduce(
    (acc, cur) => acc.concat(cur.docs),
    [] as firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
  )

  return singleArray.map((doc) => {
    const data = doc.data()
    const id = doc.id
    return {
      ...(data as AssetType),
      id,
      created: new Date(data.created),
    }
  })
}

export const fetchAssetsByCIDs = async (cids: string[]) => {
  const chunks = splitToChunks(cids, 10)
  const promises = chunks.map((chunk) =>
    firestore.collection(ASSETS_PATH).where('ipfs.jsonHash', 'in', chunk).get()
  )
  const result = await Promise.all(promises)
  const docs = result.reduce(
    (acc, cur) => acc.concat(cur.docs),
    [] as firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
  )
  return docs.map((doc) => {
    const data = doc.data()
    const id = doc.id
    return {
      ...(data as BaseAssetType),
      id,
      created: new Date(data.created),
    }
  })
}

export const fetchAssetsByIDs = async (ids: string[]) => {
  const chunks = splitToChunks(ids, 10)
  const promises = chunks.map((chunk) =>
    firestore
      .collection(ASSETS_PATH)
      .where(firebase.firestore.FieldPath.documentId(), 'in', chunk)
      .get()
  )
  const result = await Promise.all(promises)
  const docs = result.reduce(
    (acc, cur) => acc.concat(cur.docs),
    [] as firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
  )
  return docs.map((doc) => {
    const data = doc.data()
    const id = doc.id
    return {
      ...(data as BaseAssetType),
      id,
      created: new Date(data.created),
    }
  })
}

export const fetchAssets = () => {
  return firestore
    .collection(ASSETS_PATH)
    .where('status', 'in', ['PUBLISHED', 'UPDATED'])
    .orderBy('created')
    .get()
}

export const fetchAsset = ({ assetId }: { assetId: string }) => {
  return firestore.collection(ASSETS_PATH).doc(assetId).get()
}

export const fetchTenant = () => {
  return firestore
    .collection(TENANT_PATH)
    .doc(process.env.REACT_APP_TENANT_IDENTITY_ID)
    .get()
}

export const fetchTerms = async () => {
  const tenantBasedTerms = await firestore
    .collection(`/tenants/${process.env.REACT_APP_TENANT_IDENTITY_ID}/terms`)
    .orderBy('created')
    .get()

  if (tenantBasedTerms.empty)
    return await firestore.collection(TERMS_PATH).orderBy('created').get()
  else return tenantBasedTerms
}

/* =========
   FUNCTIONS
   ========= */
export const doSignedClaim = async (message: string, signature: string) => {
  const doClaimFunc = functions.httpsCallable(CLAIM_FUNCTION)
  const { data } = await doClaimFunc({
    signature,
    message,
    tenantId: process.env.REACT_APP_TENANT_IDENTITY_ID,
  }).catch((e) => {
    return Promise.reject(ClaimException.fromFirebaseError(e))
  })

  return data.deliveryId as DeliveryType['id']
}
export const doPublicClaim = async (airdropId: string) => {
  const doClaimFunc = functions.httpsCallable(CLAIM_PUBLIC_FUNCTION)
  const { data } = await doClaimFunc({
    airdropId,
    tenantId: process.env.REACT_APP_TENANT_IDENTITY_ID,
    timestamp: Date.now(),
  }).catch((e) => {
    return Promise.reject(ClaimException.fromFirebaseError(e))
  })

  return data.deliveryId as DeliveryType['id']
}

export const doPurchase = async (
  params: PurchaseParams
): Promise<PurchaseResponse> => {
  const purchase = functions.httpsCallable(PURCHASE_FUNCTION)
  const result = await purchase(params)
  return result.data
}

export const doForwardApproval = async (
  params: ForwardApprovalParams
): Promise<ForwardApprovalResponse> => {
  const forwardApproval = functions.httpsCallable(FORWARD_APPROVAL_FUNCTION)
  const result = await forwardApproval({
    ...params,
    tenantId: process.env.REACT_APP_TENANT_IDENTITY_ID,
  })
  return result.data
}

export const doCreateSignedOffer = async (
  params: CreateSignedOfferParams
): Promise<CreateSignedOfferResponse> => {
  const createSignedOffer = functions.httpsCallable(
    CREATE_SIGNED_OFFER_FUNCTION
  )
  const result = await createSignedOffer({
    ...params,
    tenantId: process.env.REACT_APP_TENANT_IDENTITY_ID,
  })
  return result.data
}

export const doUpdateWallet = async (
  message: {
    account: string
    timestamp: string
    message: string
  },
  signature: string
): Promise<void> => {
  const createSignedOffer = functions.httpsCallable(UPDATE_WALLET_FUNCTION)
  const result = await createSignedOffer({
    signature: signature,
    message: message,
    tenantId: process.env.REACT_APP_TENANT_IDENTITY_ID,
  })
  return result.data
}

export const doTransferTokensConfirm = async (
  language: string
): Promise<void> => {
  await functions.httpsCallable(TRANSFER_BATCH_REQUEST)({
    tenantId: process.env.REACT_APP_TENANT_IDENTITY_ID,
    language,
  })
}

export const doTransferTokensSettle = async (
  language: string,
  transferId: string,
  securityCode: string
): Promise<void> => {
  await functions.httpsCallable(TRANSFER_BATCH_CONFIRM)({
    tenantId: process.env.REACT_APP_TENANT_IDENTITY_ID,
    language,
    transferId,
    securityCode,
  })
}

export const observeOkenTransaction = (
  transferId: string,
  onUpdate: (transfer: PartialTransaction) => void
) => {
  return firestore
    .collection(TRANSACTIONS_PATH)
    .doc(transferId)
    .onSnapshot((doc) => {
      if (doc.exists)
        onUpdate({
          id: doc.id,
          ...doc.data(),
        } as PartialTransaction)
    })
}

export const waitForOkenTransactionToEnd = (
  okenTxId: PartialTransaction['id']
) => {
  return new Promise<void>((r, rej) => {
    observeOkenTransaction(okenTxId, (tx) => {
      if (tx.status === 'MINED') r()
      if (tx.status === 'ERROR') rej()
    })
  })
}

export const doPayment = async (
  params: PaymentParams
): Promise<PaymentResponse> => {
  const payment = functions.httpsCallable(PAYMENT_FUNCTION)
  const result = await payment(params)
  return result.data
}

export const doOpen = async (params: OpenParams): Promise<OpenResponse> => {
  const open = functions.httpsCallable(OPEN_FUNCTION)
  const result = await open(params)
  return result.data
}

export const doClaimDelivery = async (deliveryId: DeliveryType['id']) => {
  const claimDeliveryFunc = functions.httpsCallable(CLAIM_DELIVERY_FUNCTION)
  const result = await claimDeliveryFunc({
    deliveryId,
    tenantId: process.env.REACT_APP_TENANT_IDENTITY_ID,
  })

  return result.data
}

export const doWithdrawal = async (
  params: WithdrawalParams
): Promise<WithdrawalResponse> => {
  const fn = functions.httpsCallable(WITHDRAWAL_FUNCTION)
  const result = await fn(params)
  return result.data
}

export const doConfirmWithdrawal = async (
  params: ConfirmWithdrawalParams
): Promise<ConfirmWithdrawalResponse> => {
  const fn = functions.httpsCallable(CONFIRM_WITHDRAWAL_FUNCTION)
  const result = await fn(params)
  return result.data
}

export const doBid = async (
  params: Omit<BidPayload, 'tenantId'>
): Promise<BidResponse> => {
  const fn = functions.httpsCallable(BID_FUNCTION)
  const result = await fn({
    ...params,
    tenantId: process.env.REACT_APP_TENANT_IDENTITY_ID,
  } as BidPayload)
  return result.data
}

export const validatePhone = async (
  phoneNumber: string
): Promise<BidResponse> => {
  const fn = functions.httpsCallable('accounts-phoneVerificationStart')
  const result = await fn({ phoneNumber })
  return result.data
}

export const validateCode = async (
  phoneNumber: string,
  code: string
): Promise<BidResponse> => {
  const fn = functions.httpsCallable('accounts-phoneVerificationCheck')
  const result = await fn({ phoneNumber, code })
  return result.data
}

export const initFirebaseKYCIntent = () => {
  const uid = auth.currentUser?.uid!
  return firestore.collection(`/users/${uid}/verifications`).doc()
}

export const registerKYCIntent = async (
  doc: firebase.firestore.DocumentReference
) => {
  await doc.set({
    status: 'initiated_intent',
    identifier: doc.id,
  })
}

export const uploadCVMDocument = (
  documentType: 'document' | 'residency',
  file: File
) => {
  const path = `/cvm/${auth.currentUser!.uid}/${documentType}/${Date.now()}-${
    file.name
  }`
  return storage.ref(path).put(file)
}

export const getCVMDocumentLink = async (
  documentType: 'document' | 'residency'
) => {
  const path = `/cvm/${auth.currentUser!.uid}/${documentType}`
  const docs = await storage.ref(path).list({
    maxResults: 1,
  })
  return await docs.items[0].getDownloadURL()
}

export const watchCVMForm: () => SnapshotFactoryReturn<{
  id: string
  status: RarumUserProfile['cvm88Status']
}> = () => {
  return (cb) => {
    return firestore
      .collection(`/users/${auth.currentUser!.uid}/cvm88`)
      .onSnapshot((snap) => {
        const { docs } = snap
        const changes = snap.docChanges()
        if (changes.length !== 0)
          cb(
            changes.map((change) => ({
              doc: {
                id: change.doc.id,
                ...(change.doc.data() as any),
              },
              type: change.type,
            }))
          )
        else
          cb(
            docs.map((d) => ({
              doc: {
                id: d.id,
                ...(d.data() as any),
              },
              type: 'added',
            }))
          )
      })
  }
}

export const updateCVMForm = (
  formStep: keyof typeof FormStep,
  answers: Partial<{
    [questionId: string]: SerializableAnswerByField<any>
  }>,
  offerId: OfferType['id'],
  status?: RarumUserProfile['cvm88Status']
) => {
  const path =
    FormStep[formStep] === FormStep.CONTRACTS
      ? `/users/${
          auth.currentUser!.uid
        }/cvm88/${formStep}/${offerId}/${formStep}`
      : `/users/${auth.currentUser!.uid}/cvm88/${formStep}`
  const data = status
    ? {
        ...answers,
        status: status,
      }
    : answers

  firestore.doc(path).set({ ...data, updatedAt: new Date() }, { merge: true })
}

export const waitForTransferToEnd = async (tId: string) => {
  return new Promise<void>((r, rej) => {
    const unsubscribe = firestore
      .doc(
        `/tenants/${process.env.REACT_APP_TENANT_IDENTITY_ID}/transfers/${tId}`
      )
      .onSnapshot((snap) => {
        const transfer = snap.data() as TransferType
        switch (transfer.status) {
          case 'MINED':
            r()
            unsubscribe()
            break
          case 'ERROR_ON_BLOCKCHAIN':
          case 'CANCELED':
            rej(transfer.status)
            unsubscribe()
            break
        }
      })
  })
}

export const getCompletedPurchasesCount = async () => {
  return (
    await getCountFromServer(
      query(
        collection(firestore, PURCHASE_PATH),
        and(
          where('user.id', '==', auth.currentUser!.uid),
          where('paymentStatus', '==', 'PAYED')
        )
      )
    )
  ).data().count
}

export const getAirdrop = async (airdropId: string) => {
  return (
    await getDoc(
      doc(
        firestore,
        `/tenants/${process.env.REACT_APP_TENANT_IDENTITY_ID}/airdrops/${airdropId}`
      )
    )
  ).data() as ToFirebaseType<Airdrop>
}

export const watchChatRoom: (
  roomId: string
) => SnapshotFactoryReturn<ChatThread | ChatReply> = (roomId) => {
  return (cb) => {
    return firestore
      .collection(`/chats`)
      .where('roomId', '==', roomId)
      .orderBy('createdAt', 'asc')
      .onSnapshot(
        {
          includeMetadataChanges: true,
        },
        (snap) => {
          const { docs } = snap
          const changes = snap.docChanges()
          const persistedChanges = changes.filter(
            (c) => c.doc.metadata.hasPendingWrites === false
          )
          if (changes.length !== 0)
            cb(
              persistedChanges.map((change) => {
                const firestoreData = change.doc.data() as ToFirebaseType<
                  Omit<ChatReply, 'id'>
                >

                return {
                  doc: {
                    id: change.doc.id,
                    ...firestoreData,
                    createdAt: firestoreData.createdAt.toDate(),
                  },
                  type: change.type,
                }
              })
            )
          else
            cb(
              docs.map((d) => {
                const firestoreData = d.data() as ToFirebaseType<
                  Omit<ChatReply, 'id'>
                >
                return {
                  doc: {
                    id: d.id,
                    ...firestoreData,
                    createdAt: firestoreData.createdAt.toDate(),
                  },
                  type: 'added',
                }
              })
            )
        }
      )
  }
}

export function createThread(
  thread: Omit<ChatThread, 'id'> | Omit<ChatReply, 'id'>
) {
  return firestore.collection('/chats').add({
    ...thread,
    user: {
      ...thread.user,
      id: auth.currentUser!.uid,
    },
  })
}

export async function fetchChallenges(ids?: string[]): Promise<ChallengeDTO[]> {
  const constraints = ids
    ? [where(firebase.firestore.FieldPath.documentId(), 'in', ids)]
    : []

  constraints.push(where('status', '==', 'ACTIVE'))

  const { docs } = await getDocs(
    query(
      collection(
        firestore,
        `/tenants/${process.env.REACT_APP_TENANT_IDENTITY_ID}/challenges`
      ),
      ...constraints
    )
  )

  return docs.map((d) => {
    const data = d.data() as Omit<ChallengeDTO, 'id'>

    return {
      ...data,
      id: d.id,
    }
  })
}

export async function claimChallenge(
  challenge: Challenge,
  loyaltyTokenId: number | undefined
) {
  return await functions
    .httpsCallable('challenges-complete')({
      tenantId: process.env.REACT_APP_TENANT_IDENTITY_ID,
      challengeId: challenge.id,
      tokenId: loyaltyTokenId ?? '0',
    })
    .then(({ data: { transactionId } }) => transactionId as OkenIdOrMiningHash)
}

export async function approveContract(
  galleryId: string,
  contractToApprove: string,
  approve: boolean
) {
  return await functions
    .httpsCallable('accounts-approveOperator')({
      tenantId: process.env.REACT_APP_TENANT_IDENTITY_ID,
      approved: approve,
      galleryId,
      operator: contractToApprove,
    })
    .then(({ data: { transactionId } }) => transactionId as OkenIdOrMiningHash)
}
