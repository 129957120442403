import View, {
  BaseAssetInnerPadding,
  BaseAssetWidth,
  useGalleryBaseWidth,
} from './GalleryCard.view'
import { GalleryCardProps } from './GalleryCard.types'
import { useHistory } from 'react-router-dom'
import { TO_GALLERY_DETAIL } from 'core/modules/router'
import AssetsList from './AssetsList'
import { GalleryType } from 'core/logic/gallery/gallery.types'
export {
  BaseAssetInnerPadding,
  BaseAssetWidth,
  useGalleryBaseWidth,
} from './GalleryCard.view'

export const useGalleryBaseExpandedWidth = (gallery: GalleryType | undefined) =>
  useGalleryBaseWidth(gallery) + BaseAssetInnerPadding * 2 + BaseAssetWidth * 3

export default function GalleryCardLogic(props: GalleryCardProps) {
  const history = useHistory()

  return (
    <View
      {...props}
      gallery={props.gallery}
      willExpand={props.shouldExpandOnMount}
      onOpenGallery={() => {
        if (!props.banner) history.push(TO_GALLERY_DETAIL(props.gallery.id))
      }}
      banner={!!props.banner}
      className={props.className}
      ExpandedContent={AssetsList}
      version='v2'
    />
  )
}
